import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  AspectRatio,
  Card,
  CardBody,
  Heading,
  VStack,
  Badge,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Stack,
  Image,
  HStack,
  Text,
  Center,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
// import { EmailIcon } from '@chakra-ui/icons';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FiShare2, FiSend } from 'react-icons/fi';
// import { BsSearch, BsInfoCircle, BsListUl } from 'react-icons/bs';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import {
  db,
  LINK,
  masterSafetyPromotionEnabled,
  dealerIDVPortal,
} from '../constants';
import DataContext from '../context/DataContext';
import SpinnerPage from '../components/SpinnerPage';
import AppBody from '../components/AppBody';
import InviteModal from '../components/InviteModal';
import InviteIDVModal from '../components/InviteIDVModal';
import IDVShareCard from '../components/IDVShareCard';
import InviteModalWarranty from '../components/InviteModalWarranty';
import InviteModalSafetyPromo from '../components/InviteModalSafetyPromo';
import InviteModalTransaction from '../components/InviteModalTransaction';
// import logoImage from '../assets/logo.png';

function Homepage() {
  const [partnerData, setPartnerData] = useState(null);
  const toast = useToast();
  const { userData } = useContext(DataContext);
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  // const { partnerUid, active } = userData;
  const partnerUid = userData?.partnerUid;
  const active = userData?.active;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSafetyPromo,
    onOpen: onOpenSafetyPromo,
    onClose: onCloseSafetyPromo,
  } = useDisclosure();
  const {
    isOpen: isOpenWarranty,
    onOpen: onOpenWarranty,
    onClose: onCloseWarranty,
  } = useDisclosure();
  const {
    isOpen: isOpenTransaction,
    onOpen: onOpenTransaction,
    onClose: onCloseTransaction,
  } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  const {
    isOpen: isOpenIDV,
    onOpen: onOpenIDV,
    onClose: onCloseIDV,
  } = useDisclosure();
  // if (!userData) {
  //   // on first load, partnerData is not loaded yet
  //   // console.log('no datacontext')
  //   window.location.reload(false);
  // }
  // console.log('useContext(DataContext)')
  // console.log(useContext(DataContext))
  useEffect(() => {
    if (userData && !active) {
      const auth = getAuth();
      signOut(auth).then(() => {
        toast({
          title: 'Access denied',
          description: 'Membership inactive',
          status: 'warning',
          isClosable: true,
        });
        navigate('/login');
      });
    }
  }, [active, navigate, userData, toast]);

  useEffect(() => {
    if (partnerUid) {
      const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
        setPartnerData(doc.data());
      });
      return unsubscribe;
    }
  }, [partnerUid]);

  useEffect(() => {
    if (partnerUid) {
      let ref;
      if (queryParameters.get('ref')) {
        ref = queryParameters.get('ref');
        // ex /?ref=garage1
      }
      let aref;
      if (queryParameters.get('aref')) {
        aref = queryParameters.get('aref');
        // ex /?aref=affiliate1
      }
      if (queryParameters.get('a')) {
        aref = queryParameters.get('a');
        // ex /?a=affiliate1
      }
      let iref;
      if (queryParameters.get('iref')) {
        iref = queryParameters.get('iref');
        // ex /?iref=influencer1
      }
      if (queryParameters.get('i')) {
        iref = queryParameters.get('i');
        // ex /?i=influencer1
      }
      // add ref, aref or iref to user
      if (ref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          garageRef: ref,
        });
      }
      if (aref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          affiliateRef: aref,
        });
      }
      if (iref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          influencerRef: iref,
        });
      }
    }
  }, [partnerUid, queryParameters]);

  if (!partnerData) {
    return <SpinnerPage />;
  }

  if (dealerIDVPortal) {
    return (
      <>
        <Modal isOpen={isOpenIDV} onClose={onCloseIDV}>
          <ModalOverlay />
          <ModalContent>
            <InviteIDVModal />
            <ModalFooter>
              <Button
                w="full"
                variant="link"
                fontWeight="normal"
                size="sm"
                // colorScheme="primary"
                onClick={onCloseIDV}
              >
                CLOSE
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <AppBody>
          <Stack p={0} spacing={2}>
            {!partnerData?.approved && (
              <>
                <Alert
                  status="info"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  variant="left-accent"
                  textAlign="center"
                  mt={2}
                >
                  {/* <AlertIcon boxSize="30px" mr={0} /> */}
                  <AlertTitle fontSize="lg" my="4px">
                    Thank you for signing up!
                  </AlertTitle>
                  <AlertDescription
                  // maxWidth="md"
                  // mt={3}
                  >
                    A member of the team will contact you to finalize your
                    partnership
                  </AlertDescription>
                  <AlertDescription>
                    <Button
                      onClick={() => navigate('/profile')}
                      variant="link"
                      colorScheme="primary"
                      size="sm"
                    >
                      View your Profile
                    </Button>{' '}
                    to add or review the information provided
                  </AlertDescription>
                </Alert>
              </>
            )}

            <Stack align="center">
              <Text>Welcome</Text>
              <Heading size="lg" textAlign="center">
                {partnerData?.businessName}
              </Heading>
              {partnerData?.photoURL && (
                <Center>
                  <Image
                    src={partnerData?.photoURL}
                    alt="Logo"
                    width="40%"
                    maxH={'200px'}
                    objectFit="cover"
                  />
                </Center>
              )}
              <Heading size="lg" textAlign="center">
                to the{' '}
                <Text
                  as="span"
                  fontWeight="bold"
                  bgGradient="linear(to-l, primary.800,primary.300)"
                  bgClip="text"
                >
                  EEZE IDV Program
                </Text>
              </Heading>
            </Stack>

            <Stack align="center" justify="center">
              <Text as="i" m={2} color="primary.800" textAlign="center">
                Verify your customers and ensure license information has been
                captured
              </Text>
            </Stack>
            <br />
            <Wrap spacing="20px" align="center" justify="center">
              <WrapItem>
                <Card
                  direction="column"
                  overflow="hidden"
                  variant="elevated"
                  borderColor="primary.500"
                  borderWidth={1}
                  maxW="350px"
                >
                  <Image
                    objectFit="cover"
                    maxH="150px"
                    src="https://eeze.co/wp-content/uploads/2024/04/eeze-new-platform.jpg"
                    alt="Transaction"
                  />

                  <Stack>
                    <CardBody p="10px">
                      <HStack justify="space-between" lineHeight={2}>
                        <Heading size="md" justify="flex-start">
                          Identity Verify
                        </Heading>
                        <Box>
                          <VStack>
                            <Button
                              variant="link"
                              size="sm"
                              colorScheme="primary"
                              fontWeight="normal"
                              onClick={() =>
                                window.open(
                                  LINK.eezeTransactionInfoUrl,
                                  '_blank'
                                )
                              }
                              rightIcon={<ExternalLinkIcon />}
                            >
                              More info
                            </Button>
                          </VStack>
                        </Box>
                      </HStack>
                      <Text py="2">
                        Share a link with a customer to <b>Get Verified</b>
                      </Text>
                      <Button
                        w="full"
                        onClick={onOpenIDV}
                        colorScheme="primary"
                        leftIcon={<FiSend />}
                      >
                        Send Link
                      </Button>
                    </CardBody>
                  </Stack>
                </Card>
              </WrapItem>

              <WrapItem>
                <Card
                  direction="column"
                  overflow="hidden"
                  variant="elevated"
                  borderColor="primary.500"
                  borderWidth={1}
                  maxW="350px"
                >
                  <Stack>
                    <CardBody p="10px">
                      <IDVShareCard />
                    </CardBody>
                  </Stack>
                </Card>
              </WrapItem>
            </Wrap>
          </Stack>
        </AppBody>
      </>
    );
  }

  // only Ontario allowed to have safety promo
  let inOntario = false;
  const prov = partnerData?.address?.province?.toLowerCase() || '';
  if (prov.includes('on')) {
    inOntario = true;
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <InviteModal />
          <ModalFooter>
            <Button
              w="full"
              variant="link"
              fontWeight="normal"
              size="sm"
              // colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenSafetyPromo} onClose={onCloseSafetyPromo}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalSafetyPromo />
          <ModalFooter>
            <Button
              w="full"
              variant="link"
              fontWeight="normal"
              size="sm"
              onClick={onCloseSafetyPromo}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenWarranty} onClose={onCloseWarranty}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalWarranty />
          <ModalFooter>
            <Button
              w="full"
              variant="link"
              size="sm"
              fontWeight="normal"
              onClick={onCloseWarranty}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenTransaction} onClose={onCloseTransaction}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalTransaction />
          <ModalFooter>
            <Button
              w="full"
              variant="link"
              size="sm"
              fontWeight="normal"
              onClick={onCloseTransaction}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenVideo} onClose={onCloseVideo} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <AspectRatio ratio={16 / 9}>
            {/* <iframe
    title='Partner Functions'
    src='https://www.youtube.com/embed/0fCAT9425J4'
    allowFullScreen
  /> */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0fCAT9425J4?si=kalZLwTGbBrJeTeT&autoplay=1"
              title="Partner Functions"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </AspectRatio>

          <ModalFooter>
            <Button
              w="full"
              variant="link"
              size="sm"
              fontWeight="normal"
              onClick={onCloseVideo}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Header /> */}

      <AppBody>
        <Stack p={0} spacing={2}>
          {!partnerData?.approved && (
            <>
              <Alert
                status="info"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                variant="left-accent"
                textAlign="center"
                mt={2}
              >
                {/* <AlertIcon boxSize="30px" mr={0} /> */}
                <AlertTitle fontSize="lg" my="4px">
                  Thank you for signing up!
                </AlertTitle>
                <AlertDescription
                // maxWidth="md"
                // mt={3}
                >
                  A member of the team will contact you to finalize your
                  partnership
                </AlertDescription>
                <AlertDescription>
                  <Button
                    onClick={() => navigate('/profile')}
                    variant="link"
                    colorScheme="primary"
                    size="sm"
                  >
                    View your Profile
                  </Button>{' '}
                  to add or review the information provided
                </AlertDescription>
              </Alert>
            </>
          )}

          <Stack align="center">
            <Heading size="lg" textAlign="center">
              Welcome {partnerData?.businessName} to the{' '}
              <Text
                as="span"
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                EEZE Partner Program
              </Text>
            </Heading>

            {/* <Center>
                <Image
                  src={logoImage}
                  alt="Logo"
                  width="40%"
                  maxW={'500px'}
                  objectFit="cover"
                />
              </Center> */}
          </Stack>

          <Stack align="center" justify="center">
            <Text as="i" m={2} color="primary.800" textAlign="center">
              Extend dealer-level services to your customers and access new
              revenue streams by offering EEZE products
            </Text>

            {/* <Button
                colorScheme="primary"
                variant="outline"
                size="sm"
                onClick={onOpenVideo}
                mt={2}
                w='fit-content'
                leftIcon={<FaRegPlayCircle size="25px" p={2} />}
              >
                Watch Walkthrough Video
              </Button> */}
          </Stack>
          <br />
          <Wrap spacing="20px" align="center" justify="center">
            {(partnerData?.safetyPromotionEnabled ||
              masterSafetyPromotionEnabled) && 
              inOntario && 
              partnerData.type === 'garage' && 
              (
              <WrapItem>
                <Card
                  direction="column" // {{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="elevated"
                  borderColor="primary.500"
                  borderWidth={1}
                  maxW="350px"
                >
                  <Image
                    objectFit="cover"
                    maxH="150px"
                    src="https://eeze.co/wp-content/uploads/2024/08/garage-partner-inspection.jpeg"
                    alt="Safety"
                  />

                  <Stack>
                    <CardBody p="10px">
                      <HStack justify="space-between">
                        <Heading size="md">Safety Promotion</Heading>
                        <Box>
                          <VStack align="flex-end">
                            <Button
                              variant="link"
                              size="sm"
                              colorScheme="primary"
                              fontWeight="normal"
                              onClick={() =>
                                window.open(LINK.safetyPromoInfoUrl, '_blank')
                              }
                              rightIcon={<ExternalLinkIcon />}
                            >
                              More info
                            </Button>
                            <Badge
                              rounded="full"
                              px="2"
                              fontSize="xs"
                              colorScheme="red"
                              top="0"
                              position="absolute"
                            >
                              Limited Time Offer
                            </Badge>
                          </VStack>
                        </Box>
                      </HStack>
                      <Text py="2">
                        Send a link to a customer to claim their{' '}
                        <b>FREE 90-day PowerTrain Warranty</b>
                      </Text>
                      <Button
                        w="full"
                        onClick={onOpenSafetyPromo}
                        colorScheme="primary"
                        leftIcon={<FiShare2 />}
                      >
                        Send Link
                      </Button>
                    </CardBody>
                  </Stack>
                </Card>
              </WrapItem>
            )}

            <WrapItem>
              <Card
                direction="column"
                overflow="hidden"
                variant="elevated"
                borderColor="primary.500"
                borderWidth={1}
                maxW="350px"
              >
                <Image
                  objectFit="cover"
                  maxH="150px"
                  src="https://eeze.co/wp-content/uploads/2024/01/eeze-extended-warranty-driveway.jpg"
                  alt="Warranty"
                />

                <Stack>
                  <CardBody p="10px">
                    <HStack justify="space-between">
                      <Heading size="md">Vehicle Warranty</Heading>
                      <Box>
                        <VStack>
                          <Button
                            variant="link"
                            size="sm"
                            colorScheme="primary"
                            fontWeight="normal"
                            onClick={() =>
                              window.open(LINK.getWarrantyUrl, '_blank')
                            }
                            rightIcon={<ExternalLinkIcon />}
                          >
                            More info
                          </Button>
                        </VStack>
                      </Box>
                    </HStack>
                    <Text py="2">
                      Share a referral link to a customer to purchase an{' '}
                      <b>EEZE Extended Warranty</b>
                    </Text>
                    <Button
                      w="full"
                      onClick={onOpenWarranty}
                      colorScheme="primary"
                      leftIcon={<FiShare2 />}
                    >
                      Send Link
                    </Button>
                  </CardBody>
                </Stack>
              </Card>
            </WrapItem>

            <WrapItem>
              <Card
                direction="column"
                overflow="hidden"
                variant="elevated"
                borderColor="primary.500"
                borderWidth={1}
                maxW="350px"
              >
                <Image
                  objectFit="cover"
                  maxH="150px"
                  src="https://eeze.co/wp-content/uploads/2024/04/eeze-new-platform.jpg"
                  alt="Transaction"
                />

                <Stack>
                  <CardBody p="10px">
                    <HStack justify="space-between" lineHeight={2}>
                      <Heading size="md" justify="flex-start">
                        EEZE Transaction
                      </Heading>
                      <Box>
                        <VStack>
                          <Button
                            variant="link"
                            size="sm"
                            colorScheme="primary"
                            fontWeight="normal"
                            onClick={() =>
                              window.open(LINK.eezeTransactionInfoUrl, '_blank')
                            }
                            rightIcon={<ExternalLinkIcon />}
                          >
                            More info
                          </Button>
                        </VStack>
                      </Box>
                    </HStack>
                    <Text py="2">
                      Share a referral link to a customer to{' '}
                      <b>Get Verified with EEZE</b>
                    </Text>
                    <Button
                      w="full"
                      onClick={onOpen}
                      colorScheme="primary"
                      leftIcon={<FiShare2 />}
                    >
                      Send Link
                    </Button>
                  </CardBody>
                </Stack>
              </Card>
            </WrapItem>
          </Wrap>
        </Stack>
      </AppBody>
    </>
  );
}

export default Homepage;
